/* eslint-disable camelcase */
import { isObjectElement, isPrimitiveElement, isStringElement, isMemberElement, visit, toValue, cloneShallow, cloneDeep } from '@swagger-api/apidom-core';
import { isReferenceElementExternal, isReferenceLikeElement, isPathItemElementExternal, isBooleanJsonSchemaElement, ReferenceElement, PathItemElement, SchemaElement, getNodeType, keyMap } from '@swagger-api/apidom-ns-openapi-3-1';
import { evaluate as jsonPointerEvaluate, uriToPointer } from '@swagger-api/apidom-json-pointer';
import { url, MaximumDereferenceDepthError, File } from '@swagger-api/apidom-reference/configuration/empty';
import { OpenApi3_1DereferenceVisitor, resolveSchema$refField, maybeRefractToSchemaElement } from '@swagger-api/apidom-reference/dereference/strategies/openapi-3-1';
import { isAnchor, uriToAnchor, evaluate as $anchorEvaluate } from '@swagger-api/apidom-reference/dereference/strategies/openapi-3-1/selectors/$anchor';
import { evaluate as uriEvaluate, EvaluationJsonSchemaUriError } from '@swagger-api/apidom-reference/dereference/strategies/openapi-3-1/selectors/uri';
import toPath from '../utils/to-path.js';
import getRootCause from '../utils/get-root-cause.js';
import specMapMod from '../../../../../../../specmap/lib/refs.js';
import { SchemaRefError } from '../errors/index.js';
const {
  wrapError
} = specMapMod;
const visitAsync = visit[Symbol.for('nodejs.util.promisify.custom')];
const OpenApi3_1SwaggerClientDereferenceVisitor = OpenApi3_1DereferenceVisitor.compose({
  props: {
    useCircularStructures: true,
    allowMetaPatches: false,
    basePath: null
  },
  init(_ref) {
    let {
      allowMetaPatches = this.allowMetaPatches,
      useCircularStructures = this.useCircularStructures,
      basePath = this.basePath
    } = _ref;
    this.allowMetaPatches = allowMetaPatches;
    this.useCircularStructures = useCircularStructures;
    this.basePath = basePath;
  },
  methods: {
    async ReferenceElement(referencingElement, key, parent, path, ancestors) {
      try {
        var _this$basePath;
        const [ancestorsLineage, directAncestors] = this.toAncestorLineage([...ancestors, parent]);

        // detect possible cycle in traversal and avoid it
        if (ancestorsLineage.includesCycle(referencingElement)) {
          return false;
        }

        // ignore resolving external Reference Objects
        if (!this.options.resolve.external && isReferenceElementExternal(referencingElement)) {
          return false;
        }
        const reference = await this.toReference(toValue(referencingElement.$ref));
        const {
          uri: retrievalURI
        } = reference;
        const $refBaseURI = url.resolve(retrievalURI, toValue(referencingElement.$ref));
        this.indirections.push(referencingElement);
        const jsonPointer = uriToPointer($refBaseURI);

        // possibly non-semantic fragment
        let referencedElement = jsonPointerEvaluate(jsonPointer, reference.value.result);

        // applying semantics to a fragment
        if (isPrimitiveElement(referencedElement)) {
          const referencedElementType = toValue(referencingElement.meta.get('referenced-element'));
          if (isReferenceLikeElement(referencedElement)) {
            // handling indirect references
            referencedElement = ReferenceElement.refract(referencedElement);
            referencedElement.setMetaProperty('referenced-element', referencedElementType);
          } else {
            // handling direct references
            const ElementClass = this.namespace.getElementClass(referencedElementType);
            referencedElement = ElementClass.refract(referencedElement);
          }
        }

        // detect direct or indirect reference
        if (this.indirections.includes(referencedElement)) {
          throw new Error('Recursive JSON Pointer detected');
        }

        // detect maximum depth of dereferencing
        if (this.indirections.length > this.options.dereference.maxDepth) {
          throw new MaximumDereferenceDepthError(`Maximum dereference depth of "${this.options.dereference.maxDepth}" has been exceeded in file "${this.reference.uri}"`);
        }
        if (!this.useCircularStructures) {
          const hasCycles = ancestorsLineage.includes(referencedElement);
          if (hasCycles) {
            if (url.isHttpUrl(retrievalURI) || url.isFileSystemPath(retrievalURI)) {
              // make the referencing URL or file system path absolute
              return new ReferenceElement({
                $ref: $refBaseURI
              }, cloneDeep(referencingElement.meta), cloneDeep(referencingElement.attributes));
            }
            // skip processing this reference
            return false;
          }
        }

        // append referencing schema to ancestors lineage
        directAncestors.add(referencingElement);

        // dive deep into the fragment
        const visitor = OpenApi3_1SwaggerClientDereferenceVisitor({
          reference,
          namespace: this.namespace,
          indirections: [...this.indirections],
          options: this.options,
          ancestors: ancestorsLineage,
          allowMetaPatches: this.allowMetaPatches,
          useCircularStructures: this.useCircularStructures,
          basePath: (_this$basePath = this.basePath) !== null && _this$basePath !== void 0 ? _this$basePath : [...toPath([...ancestors, parent, referencingElement]), '$ref']
        });
        referencedElement = await visitAsync(referencedElement, visitor, {
          keyMap,
          nodeTypeGetter: getNodeType
        });

        // remove referencing schema from ancestors lineage
        directAncestors.delete(referencingElement);
        this.indirections.pop();
        const mergeAndAnnotateReferencedElement = refedElement => {
          const copy = cloneShallow(refedElement);

          // annotate fragment with info about original Reference element
          copy.setMetaProperty('ref-fields', {
            $ref: toValue(referencingElement.$ref),
            // @ts-ignore
            description: toValue(referencingElement.description),
            // @ts-ignore
            summary: toValue(referencingElement.summary)
          });
          // annotate fragment with info about origin
          copy.setMetaProperty('ref-origin', reference.uri);

          // override description and summary (outer has higher priority then inner)
          if (isObjectElement(refedElement)) {
            if (referencingElement.hasKey('description') && 'description' in refedElement) {
              // @ts-ignore
              copy.remove('description');
              // @ts-ignore
              copy.set('description', referencingElement.get('description'));
            }
            if (referencingElement.hasKey('summary') && 'summary' in refedElement) {
              // @ts-ignore
              copy.remove('summary');
              // @ts-ignore
              copy.set('summary', referencingElement.get('summary'));
            }
          }

          // apply meta patches
          if (this.allowMetaPatches && isObjectElement(copy)) {
            // apply meta patch only when not already applied
            if (!copy.hasKey('$$ref')) {
              const baseURI = url.resolve(retrievalURI, $refBaseURI);
              copy.set('$$ref', baseURI);
            }
          }
          return copy;
        };

        // attempting to create cycle
        if (ancestorsLineage.includes(referencedElement)) {
          if (isMemberElement(parent)) {
            parent.value = mergeAndAnnotateReferencedElement(referencedElement); // eslint-disable-line no-param-reassign
          } else if (Array.isArray(parent)) {
            parent[key] = mergeAndAnnotateReferencedElement(referencedElement); // eslint-disable-line no-param-reassign
          }

          return false;
        }

        // transclude the element for a fragment
        return mergeAndAnnotateReferencedElement(referencedElement);
      } catch (error) {
        var _this$basePath2, _this$options$derefer, _this$options$derefer2;
        const rootCause = getRootCause(error);
        const wrappedError = wrapError(rootCause, {
          baseDoc: this.reference.uri,
          $ref: toValue(referencingElement.$ref),
          pointer: uriToPointer(toValue(referencingElement.$ref)),
          fullPath: (_this$basePath2 = this.basePath) !== null && _this$basePath2 !== void 0 ? _this$basePath2 : [...toPath([...ancestors, parent, referencingElement]), '$ref']
        });
        (_this$options$derefer = this.options.dereference.dereferenceOpts) === null || _this$options$derefer === void 0 || (_this$options$derefer = _this$options$derefer.errors) === null || _this$options$derefer === void 0 || (_this$options$derefer2 = _this$options$derefer.push) === null || _this$options$derefer2 === void 0 || _this$options$derefer2.call(_this$options$derefer, wrappedError);
        return undefined;
      }
    },
    async PathItemElement(pathItemElement, key, parent, path, ancestors) {
      try {
        var _this$basePath3;
        const [ancestorsLineage, directAncestors] = this.toAncestorLineage([...ancestors, parent]);

        // ignore PathItemElement without $ref field
        if (!isStringElement(pathItemElement.$ref)) {
          return undefined;
        }

        // detect possible cycle in traversal and avoid it
        if (ancestorsLineage.includesCycle(pathItemElement)) {
          return false;
        }

        // ignore resolving external Path Item Elements
        if (!this.options.resolve.external && isPathItemElementExternal(pathItemElement)) {
          return undefined;
        }
        const reference = await this.toReference(toValue(pathItemElement.$ref));
        const {
          uri: retrievalURI
        } = reference;
        const $refBaseURI = url.resolve(retrievalURI, toValue(pathItemElement.$ref));
        this.indirections.push(pathItemElement);
        const jsonPointer = uriToPointer($refBaseURI);

        // possibly non-semantic referenced element
        let referencedElement = jsonPointerEvaluate(jsonPointer, reference.value.result);

        // applying semantics to a referenced element
        if (isPrimitiveElement(referencedElement)) {
          referencedElement = PathItemElement.refract(referencedElement);
        }

        // detect direct or indirect reference
        if (this.indirections.includes(referencedElement)) {
          throw new Error('Recursive JSON Pointer detected');
        }

        // detect maximum depth of dereferencing
        if (this.indirections.length > this.options.dereference.maxDepth) {
          throw new MaximumDereferenceDepthError(`Maximum dereference depth of "${this.options.dereference.maxDepth}" has been exceeded in file "${this.reference.uri}"`);
        }
        if (!this.useCircularStructures) {
          const hasCycles = ancestorsLineage.includes(referencedElement);
          if (hasCycles) {
            if (url.isHttpUrl(retrievalURI) || url.isFileSystemPath(retrievalURI)) {
              // make the referencing URL or file system path absolute
              return new PathItemElement({
                $ref: $refBaseURI
              }, cloneDeep(pathItemElement.meta), cloneDeep(pathItemElement.attributes));
            }
            // skip processing this path item and all it's child elements
            return false;
          }
        }

        // append referencing schema to ancestors lineage
        directAncestors.add(pathItemElement);

        // dive deep into the referenced element
        const visitor = OpenApi3_1SwaggerClientDereferenceVisitor({
          reference,
          namespace: this.namespace,
          indirections: [...this.indirections],
          options: this.options,
          ancestors: ancestorsLineage,
          allowMetaPatches: this.allowMetaPatches,
          useCircularStructures: this.useCircularStructures,
          basePath: (_this$basePath3 = this.basePath) !== null && _this$basePath3 !== void 0 ? _this$basePath3 : [...toPath([...ancestors, parent, pathItemElement]), '$ref']
        });
        referencedElement = await visitAsync(referencedElement, visitor, {
          keyMap,
          nodeTypeGetter: getNodeType
        });

        // remove referencing schema from ancestors lineage
        directAncestors.delete(pathItemElement);
        this.indirections.pop();
        const mergeAndAnnotateReferencedElement = refedElement => {
          // merge fields from referenced Path Item with referencing one
          const mergedElement = new PathItemElement([...refedElement.content], cloneDeep(refedElement.meta), cloneDeep(refedElement.attributes));
          // existing keywords from referencing PathItemElement overrides ones from referenced element
          pathItemElement.forEach((value, keyElement, item) => {
            mergedElement.remove(toValue(keyElement));
            mergedElement.content.push(item);
          });
          mergedElement.remove('$ref');

          // annotate referenced element with info about original referencing element
          mergedElement.setMetaProperty('ref-fields', {
            $ref: toValue(pathItemElement.$ref)
          });
          // annotate referenced element with info about origin
          mergedElement.setMetaProperty('ref-origin', reference.uri);

          // apply meta patches
          if (this.allowMetaPatches) {
            // apply meta patch only when not already applied
            if (typeof mergedElement.get('$$ref') === 'undefined') {
              const baseURI = url.resolve(retrievalURI, $refBaseURI);
              mergedElement.set('$$ref', baseURI);
            }
          }
          return mergedElement;
        };

        // attempting to create cycle
        if (ancestorsLineage.includes(referencedElement)) {
          if (isMemberElement(parent)) {
            parent.value = mergeAndAnnotateReferencedElement(referencedElement); // eslint-disable-line no-param-reassign
          } else if (Array.isArray(parent)) {
            parent[key] = mergeAndAnnotateReferencedElement(referencedElement); // eslint-disable-line no-param-reassign
          }

          return false;
        }

        // transclude referencing element with merged referenced element
        return mergeAndAnnotateReferencedElement(referencedElement);
      } catch (error) {
        var _this$basePath4, _this$options$derefer3, _this$options$derefer4;
        const rootCause = getRootCause(error);
        const wrappedError = wrapError(rootCause, {
          baseDoc: this.reference.uri,
          $ref: toValue(pathItemElement.$ref),
          pointer: uriToPointer(toValue(pathItemElement.$ref)),
          fullPath: (_this$basePath4 = this.basePath) !== null && _this$basePath4 !== void 0 ? _this$basePath4 : [...toPath([...ancestors, parent, pathItemElement]), '$ref']
        });
        (_this$options$derefer3 = this.options.dereference.dereferenceOpts) === null || _this$options$derefer3 === void 0 || (_this$options$derefer3 = _this$options$derefer3.errors) === null || _this$options$derefer3 === void 0 || (_this$options$derefer4 = _this$options$derefer3.push) === null || _this$options$derefer4 === void 0 || _this$options$derefer4.call(_this$options$derefer3, wrappedError);
        return undefined;
      }
    },
    async SchemaElement(referencingElement, key, parent, path, ancestors) {
      try {
        var _this$basePath5;
        const [ancestorsLineage, directAncestors] = this.toAncestorLineage([...ancestors, parent]);

        // skip current referencing schema as $ref keyword was not defined
        if (!isStringElement(referencingElement.$ref)) {
          // skip traversing this schema but traverse all it's child schemas
          return undefined;
        }

        // detect possible cycle in traversal and avoid it
        if (ancestorsLineage.includesCycle(referencingElement)) {
          return false;
        }

        // compute baseURI using rules around $id and $ref keywords
        let reference = await this.toReference(url.unsanitize(this.reference.uri));
        let {
          uri: retrievalURI
        } = reference;
        const $refBaseURI = resolveSchema$refField(retrievalURI, referencingElement);
        const $refBaseURIStrippedHash = url.stripHash($refBaseURI);
        const file = File({
          uri: $refBaseURIStrippedHash
        });
        const isUnknownURI = !this.options.resolve.resolvers.some(r => r.canRead(file));
        const isURL = !isUnknownURI;
        const isExternal = isURL && retrievalURI !== $refBaseURIStrippedHash;

        // ignore resolving external Schema Objects
        if (!this.options.resolve.external && isExternal) {
          // skip traversing this schema but traverse all it's child schemas
          return undefined;
        }
        this.indirections.push(referencingElement);

        // determining reference, proper evaluation and selection mechanism
        let referencedElement;
        try {
          if (isUnknownURI || isURL) {
            // we're dealing with canonical URI or URL with possible fragment
            const selector = $refBaseURI;
            referencedElement = uriEvaluate(selector, maybeRefractToSchemaElement(reference.value.result));
          } else {
            // we're assuming here that we're dealing with JSON Pointer here
            reference = await this.toReference(url.unsanitize($refBaseURI));
            retrievalURI = reference.uri;
            const selector = uriToPointer($refBaseURI);
            referencedElement = maybeRefractToSchemaElement(jsonPointerEvaluate(selector, reference.value.result));
          }
        } catch (error) {
          /**
           * No SchemaElement($id=URL) was not found, so we're going to try to resolve
           * the URL and assume the returned response is a JSON Schema.
           */
          if (isURL && error instanceof EvaluationJsonSchemaUriError) {
            if (isAnchor(uriToAnchor($refBaseURI))) {
              // we're dealing with JSON Schema $anchor here
              reference = await this.toReference(url.unsanitize($refBaseURI));
              retrievalURI = reference.uri;
              const selector = uriToAnchor($refBaseURI);
              referencedElement = $anchorEvaluate(selector, maybeRefractToSchemaElement(reference.value.result));
            } else {
              // we're assuming here that we're dealing with JSON Pointer here
              reference = await this.toReference(url.unsanitize($refBaseURI));
              retrievalURI = reference.uri;
              const selector = uriToPointer($refBaseURI);
              referencedElement = maybeRefractToSchemaElement(jsonPointerEvaluate(selector, reference.value.result));
            }
          } else {
            throw error;
          }
        }

        // detect direct or indirect reference
        if (this.indirections.includes(referencedElement)) {
          throw new Error('Recursive Schema Object reference detected');
        }

        // detect maximum depth of dereferencing
        if (this.indirections.length > this.options.dereference.maxDepth) {
          throw new MaximumDereferenceDepthError(`Maximum dereference depth of "${this.options.dereference.maxDepth}" has been exceeded in file "${this.reference.uri}"`);
        }

        // useCircularStructures option processing
        if (!this.useCircularStructures) {
          const hasCycles = ancestorsLineage.some(ancs => ancs.has(referencedElement));
          if (hasCycles) {
            if (url.isHttpUrl(retrievalURI) || url.isFileSystemPath(retrievalURI)) {
              // make the referencing URL or file system path absolute
              const baseURI = url.resolve(retrievalURI, $refBaseURI);
              return new SchemaElement({
                $ref: baseURI
              }, cloneDeep(referencingElement.meta), cloneDeep(referencingElement.attributes));
            }
            // skip processing this schema and all it's child schemas
            return false;
          }
        }

        // append referencing schema to ancestors lineage
        directAncestors.add(referencingElement);

        // dive deep into the fragment
        const mergeVisitor = OpenApi3_1SwaggerClientDereferenceVisitor({
          reference,
          namespace: this.namespace,
          indirections: [...this.indirections],
          options: this.options,
          useCircularStructures: this.useCircularStructures,
          allowMetaPatches: this.allowMetaPatches,
          ancestors: ancestorsLineage,
          basePath: (_this$basePath5 = this.basePath) !== null && _this$basePath5 !== void 0 ? _this$basePath5 : [...toPath([...ancestors, parent, referencingElement]), '$ref']
        });
        referencedElement = await visitAsync(referencedElement, mergeVisitor, {
          keyMap,
          nodeTypeGetter: getNodeType
        });

        // remove referencing schema from ancestors lineage
        directAncestors.delete(referencingElement);
        this.indirections.pop();
        if (isBooleanJsonSchemaElement(referencedElement)) {
          const booleanJsonSchemaElement = cloneDeep(referencedElement);
          // annotate referenced element with info about original referencing element
          booleanJsonSchemaElement.setMetaProperty('ref-fields', {
            $ref: toValue(referencingElement.$ref)
          });
          // annotate referenced element with info about origin
          booleanJsonSchemaElement.setMetaProperty('ref-origin', reference.uri);
          return booleanJsonSchemaElement;
        }
        const mergeAndAnnotateReferencedElement = refedElement => {
          // Schema Object - merge keywords from referenced schema with referencing schema
          const mergedElement = new SchemaElement([...refedElement.content], cloneDeep(refedElement.meta), cloneDeep(refedElement.attributes));
          // existing keywords from referencing schema overrides ones from referenced schema
          referencingElement.forEach((value, keyElement, item) => {
            mergedElement.remove(toValue(keyElement));
            mergedElement.content.push(item);
          });
          mergedElement.remove('$ref');
          // annotate referenced element with info about original referencing element
          mergedElement.setMetaProperty('ref-fields', {
            $ref: toValue(referencingElement.$ref)
          });
          // annotate fragment with info about origin
          mergedElement.setMetaProperty('ref-origin', reference.uri);

          // allowMetaPatches option processing
          if (this.allowMetaPatches) {
            // apply meta patch only when not already applied
            if (typeof mergedElement.get('$$ref') === 'undefined') {
              const baseURI = url.resolve(retrievalURI, $refBaseURI);
              mergedElement.set('$$ref', baseURI);
            }
          }
          return mergedElement;
        };

        // attempting to create cycle
        if (ancestorsLineage.includes(referencedElement)) {
          if (isMemberElement(parent)) {
            parent.value = mergeAndAnnotateReferencedElement(referencedElement); // eslint-disable-line no-param-reassign
          } else if (Array.isArray(parent)) {
            parent[key] = mergeAndAnnotateReferencedElement(referencedElement); // eslint-disable-line no-param-reassign
          }

          return false;
        }

        // transclude referencing element with merged referenced element
        return mergeAndAnnotateReferencedElement(referencedElement);
      } catch (error) {
        var _this$basePath6, _this$options$derefer5, _this$options$derefer6;
        const rootCause = getRootCause(error);
        const wrappedError = new SchemaRefError(`Could not resolve reference: ${rootCause.message}`, {
          baseDoc: this.reference.uri,
          $ref: toValue(referencingElement.$ref),
          fullPath: (_this$basePath6 = this.basePath) !== null && _this$basePath6 !== void 0 ? _this$basePath6 : [...toPath([...ancestors, parent, referencingElement]), '$ref']
        }, rootCause);
        (_this$options$derefer5 = this.options.dereference.dereferenceOpts) === null || _this$options$derefer5 === void 0 || (_this$options$derefer5 = _this$options$derefer5.errors) === null || _this$options$derefer5 === void 0 || (_this$options$derefer6 = _this$options$derefer5.push) === null || _this$options$derefer6 === void 0 || _this$options$derefer6.call(_this$options$derefer5, wrappedError);
        return undefined;
      }
    },
    async LinkElement() {
      /**
       * OpenApi3_1DereferenceVisitor is doing lookup of Operation Objects
       * and assigns them to Link Object metadata. This is not needed in
       * swagger-client context, so we're disabling it here.
       */
      return undefined;
    },
    async ExampleElement(exampleElement, key, parent, path, ancestors) {
      try {
        return await OpenApi3_1DereferenceVisitor.compose.methods.ExampleElement.call(this, exampleElement, key, parent, path, ancestors);
      } catch (error) {
        var _this$basePath7, _this$options$derefer7, _this$options$derefer8;
        const rootCause = getRootCause(error);
        const wrappedError = wrapError(rootCause, {
          baseDoc: this.reference.uri,
          externalValue: toValue(exampleElement.externalValue),
          fullPath: (_this$basePath7 = this.basePath) !== null && _this$basePath7 !== void 0 ? _this$basePath7 : [...toPath([...ancestors, parent, exampleElement]), 'externalValue']
        });
        (_this$options$derefer7 = this.options.dereference.dereferenceOpts) === null || _this$options$derefer7 === void 0 || (_this$options$derefer7 = _this$options$derefer7.errors) === null || _this$options$derefer7 === void 0 || (_this$options$derefer8 = _this$options$derefer7.push) === null || _this$options$derefer8 === void 0 || _this$options$derefer8.call(_this$options$derefer7, wrappedError);
        return undefined;
      }
    }
  }
});
export default OpenApi3_1SwaggerClientDereferenceVisitor;
/* eslint-enable camelcase */