import resolveOpenAPI31Strategy from './resolve.js';
import normalize, { pojoAdapter } from './normalize.js';
import { isOpenAPI31 } from '../../../helpers/openapi-predicates.js';
const openApi31ApiDOMStrategy = {
  name: 'openapi-3-1-apidom',
  match(_ref) {
    let {
      spec
    } = _ref;
    return isOpenAPI31(spec);
  },
  normalize(_ref2) {
    let {
      spec
    } = _ref2;
    return pojoAdapter(normalize)(spec);
  },
  async resolve(options) {
    return resolveOpenAPI31Strategy(options);
  }
};
export default openApi31ApiDOMStrategy;